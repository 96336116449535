/* ----------------------------------------------------------------------------------------------------- */
/*  @ Import third party library styles here.
/* ----------------------------------------------------------------------------------------------------- */
/* Roboto font */
@import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');

/* Perfect scrollbar */
@import '~perfect-scrollbar/css/perfect-scrollbar.css';

/* Quill */
@import '~quill/dist/quill.snow.css';
